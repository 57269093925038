<template>
    <div class="container">
        <h1>teste zip:</h1>
        <b-button class="m-1" @click="criarZip()" variant="outline-primary" style="width: fit-content">Gerar ZIP</b-button>
        {{meta_dados_pdf}}
        <!-- {{formulario_escolhido}} -->
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var JSZip = require("jszip");
// var firestorage = firebase.storage();



// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);


export default {
    data() {
        return {
            formularios: [],
            campus_uninove: [],
            curso_selecionado: '',
            campus_selecionado: '',
            meta_dados_pdf: [],
            formulario_escolhido: {}
        }
    },
    computed: {
        
    },
    methods:{
        toPdf(){            
            var docDefinition = {
                content: this.meta_dados_pdf,
                // pageSize: 'A4',
                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [ 80, 60, 80, 80 ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true
                    }
                }
            };

            pdfMake.createPdf(docDefinition).open();
        },

        criarZip(){
            var zip = new JSZip();
            this.criarPdf()

            zip.file("Hello.txt", this.meta_dados_pdf);
            var img = zip.folder("images");
            img.file("smile.gif", '', {'base64': true});
            //  zip.generateAsync({type: "blob"}).then(function (blob) {
            //         // firestorage.ref('teste/zipado.zip').put(blob)
            //         console.log(blob)
            // },
            //  function (err) {
            //     console.log(err);
            // });
            zip.generateAsync({type:"base64"})
            .then(function (content) {
                location.href="data:application/zip;base64,"+content;
            });  
        },

        solicitandoFirebase(){
            // Solicitando os cursos
            db.collection("/nadir")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let dados_formulario = doc.data()
                    dados_formulario['id'] = doc.ref.id
                    this.formularios.push(dados_formulario)
                });
                console.log(this.formularios)
                this.formulario_escolhido = this.formularios[38].formulario_especifico
                // this.criarPdf()
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

            // Solicitando os campus
            db.collection("/campus_uninove")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let campus = doc.data()
                    this.campus_uninove.push(campus)
                });            
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        createToc(){
            this.meta_dados_pdf.push({ toc: { title: {text: 'Sumário', style: 'header'} } })
        },

        newHeader(titulo){
            this.meta_dados_pdf.push({text: titulo, style: 'header', bold: true, tocItem: true})
            this.meta_dados_pdf.push({text: titulo, style: 'header', pageBreak: 'before', bold: true, tocItem: true})
        },

        newSubHeader(subtitulo){
            this.meta_dados_pdf.push({text: subtitulo, style: 'header', bold: true, tocItem: true})
        },

        newText(text){
            this.meta_dados_pdf.push([{text: text, bold: false, alignment: 'justify'}, '\n\n'])
            // this.meta_dados_pdf.push([{text: text.replace(/(\r\n|\n|\r)/gm, " "), bold: false, alignment: 'justify'}, '\n\n'])
        },

        // newImg(){
        //     this.meta_dados_pdf.push({image: '/vLhFeevTgQNkxiVdFvBM.png', width: 450})
        // },

        createTableItem_4_2_1(item_4_2_1){
            let tables = []
            // body.push
            console.log(item_4_2_1)
            item_4_2_1.forEach((x) => {
                let tabela = { style: 'tableExample', table: { body: []}, margin: [0, 5, 0, 15]}
                let body = tabela.table.body
                // body.push([{text: 'UNIDADE CURRICULAR: Eletricidade CC', bold: true, fillColor: '#dddddd', alignment: 'center'}])
                // body.push([x.semestre + '° SEMESTRE'])
                // body.push(['CARGA HORÁRIA: ' + x.carga_horaria])
                // body.push(['MODALIDADE: ' + x.modalidade])
                // body.push([{text: 'EMENTA', bold: true, fillColor: '#dddddd'}]) 
                body.push([x.ementa])   

                // let competencias = {stack: []}
                // x.competencias.forEach(y => {
                //     competencias.stack.push({text: y.nome, bold: true})
                //     competencias.stack.push({ul: [
                //         'Conhecimento: conhecer tudo' + y.conhecimento, 
                //         'Habilidade: ser hábil' + y.habilidade, 
                //         'Atitude: ter atitude' + y.atitude
                //     ]})
                // })
                // body.push([competencias])

                body.push([{text: 'AVALIAÇÃO', bold: true, fillColor: '#dddddd'}]) 
                body.push([x.avaliacao])
                body.push([{text: 'PRÁTICAS', bold: true, fillColor: '#dddddd'}]) 
                body.push([x.praticas])
                tables.push(tabela)
            })
            

            this.meta_dados_pdf.push(tables)
        },

        



        criarPdf(){
            // this.createToc()
            this.createTableItem_4_2_1(this.formulario_escolhido.item_4_2_1)
            
            // _________________ 1. Apresentação e justificativa. _________________
            this.newHeader('1. Apresentação e justificativa')

            // 1.1 Apresentação da Instituição.
            this.newSubHeader('1.1 Apresentação da Instituição')
            this.newText(this.formulario_escolhido.item_4_10)
            // 1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)
            this.newSubHeader('1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)')
            this.newText(this.formulario_escolhido.item_4_10)
            // 1.3 Justificativa para oferta do curso
            this.newSubHeader('1.3 Justificativa para oferta do curso')
            this.newText(this.formulario_escolhido.item_1_3)
            // 1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade)
            this.newSubHeader('1.4. Relevância da oferta')
            this.newText(this.formulario_escolhido.item_1_4)
            // 1.5. Objetivos do Curso:
            this.newSubHeader('1.5. Objetivos do Curso')
            this.newText(this.formulario_escolhido.item_1_5)
            // 1.5.1. Objetivo Geral
            this.newSubHeader('1.5.1. Objetivo Geral')
            this.newText(this.formulario_escolhido.item_1_5_1)
            // 1.5.2. Objetivos Específicos.
            this.newSubHeader('1.5.2. Objetivos Específicos')
            this.newText(this.formulario_escolhido.item_1_5_2)


            // _________________ 2. Formas de acesso _________________
            this.newHeader('2. Formas de acesso')
            // 2.1. Pré-requisitos para acesso ao curso
            // 2.2 Formas de acesso ao curso


            // _________________ 3. Perfil profissional de conclusão _________________
            this.newHeader('3. Perfil profissional de conclusão')
            // 3.1 Habilidades e competências desenvolvidas na formação


            // _________________ 4. Organização Curricular _________________
            this.newHeader('4. Organização Curricular')
            // 4.1. Representação gráfica da organização curricular e do itinerário formativo
            this.newSubHeader('4.1. Representação gráfica da organização curricular e do itinerário formativo')
            // this.newImg()

            // 4.2. Apresentação das Unidades Curriculares
            this.newSubHeader('4.2. Apresentação das Unidades Curriculares')
            // 4.2.1. Ementários das Unidades
            this.newSubHeader('4.2.1. Ementários das Unidades')
            

            // 4.3. Plano de realização do Estágio Curricular (somente Enf E radio)
            this.newSubHeader('4.3. Plano de realização do Estágio Curricular')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores
            this.newSubHeader('4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem
            this.newSubHeader('4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.6 Critérios de avaliação da aprendizagem
            this.newSubHeader('4.6 Critérios de avaliação da aprendizagem')
            // 4.6.1 Estratégias de acompanhamento e superação de dificuldades
            this.newSubHeader('4.6.1 Estratégias de acompanhamento e superação de dificuldades')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.6.2 Estratégias de recuperação
            this.newSubHeader('4.6.2 Estratégias de recuperação')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.6.3 Promoção/Pendência
            this.newSubHeader('4.6.3 Promoção/Pendência')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.7. Regras de Trancamento
            this.newSubHeader('4.7. Regras de Trancamento')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado
            this.newSubHeader('4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.9 Estágio supervisionado
            this.newSubHeader('4.9 Estágio supervisionado')
            // 4.9.1 Objetivos
            this.newSubHeader('4.9.1 Objetivos')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.9.2 Ambientes de estágio
            this.newSubHeader('4.9.2 Ambientes de estágio')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.9.3 Distribuição da carga horária em cada ambiente
            this.newSubHeader('4.9.3 Distribuição da carga horária em cada ambiente')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.10 Atividades práticas previstas
            this.newSubHeader('4.10 Atividades práticas previstas')
            this.newText(this.formulario_escolhido.item_4_10)



            // _________________ 5. Instalações e equipamentos _________________
            this.newHeader('5. Instalações e equipamentos')
            // 5.1 Salas de aula
            this.newSubHeader('5.1 Salas de aula')
            // 5.2 Relação de laboratórios
            this.newSubHeader('5.2 Relação de laboratórios')
            // 5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas
            this.newSubHeader('5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas')
            // 5.4 Outras instalações
            this.newSubHeader('5.4 Outras instalações')
            // 5.5 Recursos de acessibilidade
            this.newSubHeader('5.5 Recursos de acessibilidade')
            // 5.6 Biblioteca
            this.newSubHeader('5.6 Biblioteca')
            // 5.7 Acervo bibliográfico do curso
            this.newSubHeader('5.7 Acervo bibliográfico do curso')



            // _________________ 6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo _________________
            this.newHeader('6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo')
            // 6.1. Corpo Docente
            this.newSubHeader('6.1. Corpo Docente')
            // 6.2. Corpo Tutorial
            this.newSubHeader('6.2. Corpo Tutorial')
            // 6.3. Coordenador Pedagógico do Curso
            this.newSubHeader('6.3. Coordenador Pedagógico do Curso')
            // 6.4. Pessoal Técnico Administrativo
            this.newSubHeader('6.4. Pessoal Técnico Administrativo')



            // _________________ 7. Diplomas e Certificados Expedidos _________________
            this.newHeader('7. Diplomas e Certificados Expedidos')
            // 7.1 Descrição das informações do diploma a ser emitido
            this.newSubHeader('7.1 Descrição das informações do diploma a ser emitido')
            this.newText(this.formulario_escolhido.item_4_10)
            // 7.2 Descrição das informações dos certificados de qualificação profissional
            this.newSubHeader('7.2 Descrição das informações dos certificados de qualificação profissional')
            this.newText(this.formulario_escolhido.item_4_10)



            // _________________ 8. Descrição do Ambiente Virtual de Aprendizagem _________________
            this.newHeader('8. Descrição do Ambiente Virtual de Aprendizagem')
            this.newHeader('7.2 Descrição das informações dos certificados de qualificação profissional')
            this.newText(this.formulario_escolhido.item_4_10)




            // _________________ 9. Anexos _________________
            this.newHeader('9. Anexos')
            // 9.1 Enxerto do Regimento Interno
            // 9.2 Relação dos Polos de apoio presencial
            // 9.3 Termo de Responsabilidade e Veracidade



            // _________________ 10. Link das Informações e Imagens _________________
            this.newHeader('10. Link das Informações e Imagens')




        }

    },
    mounted(){
        this.solicitandoFirebase()


    }
    
}
</script>